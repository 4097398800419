// src/pages/LoginPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const URL = window.env.REACT_APP_API_URL;

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${URL}login`, {
                username,
                password,
            });
    
            const data = response.data;
            if (data.success) {
                const thisUser = data.user;
                const isAdminResponse = await axios.get(`${URL}isAdmin/${thisUser.UserID}`);
                const isAdmin = isAdminResponse.data;
    
                login(thisUser, isAdmin);
                navigate('/home');
            }
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    alert('Incorrect Username or Password');
                } else if (status === 403) {
                    alert('Your account is deactivated. Please contact the administrator.');
                } else if (status === 500) {
                    alert('Internal server error');
                } else {
                    alert('An unexpected error occurred.');
                }
            } else {
                alert('Network error. Please try again.');
            }
        }
    };    

    return (
        <div className="login-container">
            <div className="container login">
                <h1>Please Sign In</h1>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input 
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input 
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        />
                    </div>
                    <button type="submit" id="loginButton">Sign In</button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
