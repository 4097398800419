import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const SOCLOConnectionManager = () => {
    const [years, setYears] = useState([]);
    const [courses, setCourses] = useState([]);
    const [CLOs, setCLOs] = useState([]);
    const [SOs, setSOs] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [SOCLOConnections, setSOCLOConnections] = useState([]);
    const [selectedCLO, setSelectedCLO] = useState(null);
    const [selectedSO, setSelectedSO] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchData();

        const handleCourseListUpdated = () => fetchCourses();
        const handleCLOListUpdated = () => fetchCLOs();
        window.addEventListener('courseListUpdated', handleCourseListUpdated);
        window.addEventListener('CLOListUpdated', handleCLOListUpdated);

        return () => {
            window.removeEventListener('courseListUpdated', handleCourseListUpdated);
            window.removeEventListener('CLOListUpdated', handleCLOListUpdated);
        };
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const yearsResponse = await axios.get(`${URL}years`);
            setYears(yearsResponse.data);

            const coursesResponse = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(coursesResponse.data);

            const CLOsResponse = await axios.get(`${URL}getCLOs/${user.UserID}`);
            setCLOs(CLOsResponse.data);

            const SOsResponse = await axios.get(`${URL}getSO/${user.UserID}`);
            setSOs(SOsResponse.data);

            const assessmentsResponse = await axios.get(`${URL}getAssessmentPlans/${user.UserID}`);
            setAssessments(assessmentsResponse.data);

            const SOCLOConnectionsResponse = await axios.get(`${URL}getSOCLOs/${user.UserID}`);
            setSOCLOConnections(SOCLOConnectionsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Error fetching data");
        } finally {
            setLoading(false);
        }
    };

    const fetchCourses = async () => {
        try {
            const coursesResponse = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(coursesResponse.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
            setError("Failed to load courses");
        }
    };

    const fetchCLOs = async () => {
        try {
            const CLOsResponse = await axios.get(`${URL}getCLOs/${user.UserID}`);
            setCLOs(CLOsResponse.data);
        } catch (error) {
            console.error("Error fetching CLOs:", error);
            setError("Failed to load CLOs");
        }
    };

    const createConnection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}createSOCLOConnection`, {
                CLOID: selectedCLO.value,
                SOID: selectedSO.value,
                CourseID: selectedCourse.value,
                CYear: selectedYear.value,
                User: user
            });
            setSelectedCLO(null);
            setSelectedSO(null);
            setSelectedCourse(null);
            setSelectedYear(null);
            alert("Connection Created");
            fetchData();
        } catch (error) {
            console.error("Error creating connection:", error);
            setError("Failed to create connection");
        } finally {
            setLoading(false);
        }
    };

    const removeConnection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const [cloID, soID, courseID, year] = selectedConnection.value.split('-');
            await axios.delete(`${URL}removeSOCLOConnection`, {
                data: {
                    CLOID: cloID,
                    SOID: soID,
                    CourseID: courseID,
                    CYear: year,
                    User: user
                }
            });
            setSelectedConnection(null);
            alert("Connection Removed");
            fetchData();
        } catch (error) {
            console.error("Error removing connection:", error);
            setError("Failed to remove connection");
        } finally {
            setLoading(false);
        }
    };

    const filteredCLOs = selectedSO && selectedCourse && selectedYear
        ? CLOs.filter(clo => {
            // Find assessment plans that match the selected year and course
            const validAssessments = assessments.filter(assessment => 
                assessment.CYear === selectedYear.value && 
                assessment.CourseID === selectedCourse.value
            );

            // Check if the CLO is associated with any of these valid assessment plans
            const isAssociatedWithCourse = validAssessments.some(assessment => assessment.AssessID === clo.AssessID);

            // Ensure the CLO is not already connected to the selected SO, Course, and Year
            const isNotConnected = !SOCLOConnections.some(conn => 
                conn.SOID === selectedSO.value &&
                conn.CourseID === selectedCourse.value &&
                conn.CYear === selectedYear.value &&
                conn.CLOID === clo.CLOID
            );

            // Return true if the CLO is associated with the course and is not connected yet
            return isAssociatedWithCourse && isNotConnected;
        })
        : [];

    const filteredSOs = selectedYear //it now only shows SOs that are associated with the selected year
        ? SOs.filter(so => so.CYear === selectedYear.value)
        : [];    

    const filteredCourses = selectedYear //it now only shows courses that have assessment plans for that year
        ? courses.filter(course => 
            assessments.some(assessment => 
                assessment.CYear === selectedYear.value && assessment.CourseID === course.CourseID
            )
        )
        : [];    

    const yearOptions = [
        { value: '', label: '-- Select Year --' },
        ...years.map(year => ({ value: year.CYear, label: year.CYear.toString() }))
    ];        

    const cloOptions = [
        { value: '', label: '-- Select CLO --' },
        ...filteredCLOs.map(clo => ({ value: clo.CLOID, label: `${clo.CLONumber} - ${clo.CLOComments}` }))
    ];

    const soOptions = [
        { value: '', label: '-- Select SO --' },
        ...filteredSOs.map(so => ({ value: so.SOID, label: `${so.SONumber} - ${so.SODescription}` }))
    ];

    const courseOptions = [
        { value: '', label: '-- Select Course --' },
        ...filteredCourses.map(course => ({ value: course.CourseID, label: `${course.CourseNum} - ${course.CourseName}` }))
    ];

    const connectionOptions = [
        { value: '', label: '-- Select SO-CLO Connection --' },
        ...SOCLOConnections.map(conn => ({
            value: `${conn.CLOID}-${conn.SOID}-${conn.CourseID}-${conn.CYear}`,
            label: `CLO: ${conn.CLONumber} SO: ${conn.SONumber} Course: ${conn.CourseNum} Year: ${conn.CYear}`
        }))
    ];

    return (
        <section id="SO-CLOManager">
            <h1>SO-CLO Connection Manager</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Create Connection</h2>
            <form onSubmit={createConnection}>
                <Select
                    value={selectedYear}
                    onChange={setSelectedYear}
                    options={yearOptions}
                    placeholder="-- Select Year --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedYear && (
                    <Select
                        value={selectedSO}
                        onChange={setSelectedSO}
                        options={soOptions}
                        placeholder="-- Select SO --"
                        isSearchable
                        required
                        styles={selectStyles}
                    />
                )}
                {selectedSO && (
                    <Select
                        value={selectedCourse}
                        onChange={setSelectedCourse}
                        options={courseOptions}
                        placeholder="-- Select Course --"
                        isSearchable
                        required
                        styles={selectStyles}
                    />
                )}
                {selectedCourse && (
                    <Select
                        value={selectedCLO}
                        onChange={setSelectedCLO}
                        options={cloOptions}
                        placeholder="-- Select CLO --"
                        isSearchable
                        required
                        styles={selectStyles}
                    />
                )}
                <button type="submit" disabled={!selectedCLO}>Submit</button>
            </form>
            <h2>Remove Connection</h2>
            <form onSubmit={removeConnection}>
                <Select
                    value={selectedConnection}
                    onChange={setSelectedConnection}
                    options={connectionOptions}
                    placeholder="-- Select SO-CLO Connection --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
}

export default SOCLOConnectionManager;
