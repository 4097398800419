import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const UseCourseConnectionManager = () => {
    const [faculty, setFaculty] = useState([]);
    const [courses, setCourses] = useState([]);
    const [connections, setConnections] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchData();

        const handleCourseListUpdated = () => fetchCourses();
        window.addEventListener('courseListUpdated', handleCourseListUpdated);

        return () => {
            window.removeEventListener('courseListUpdated', handleCourseListUpdated);
        };
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const facultyResponse = await axios.get(`${URL}getFaculty/${user.UserID}`);
            setFaculty(facultyResponse.data);
            
            const coursesResponse = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(coursesResponse.data);
            
            const connectionsResponse = await axios.get(`${URL}getConnections/${user.UserID}`);
            setConnections(connectionsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load data");
        } finally {
            setLoading(false);
        }
    };

    const fetchCourses = async () => {
        try {
            const coursesResponse = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(coursesResponse.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
            setError("Failed to load courses");
        }
    };

    const createConnection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}createUseCourseConnection`, {
                FAUserID: selectedFaculty.value,
                CourseID: selectedCourse.value,
                User: user
            });
            setSelectedFaculty(null);
            setSelectedCourse(null);
            alert("connection created");
            fetchData();
        } catch (error) {
            console.error("Error creating connection:", error);
            setError("Failed to create connection");
        } finally {
            setLoading(false);
        }
    };

    const removeConnection = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const [facultyID, courseID] = selectedConnection.value.split('-');
            await axios.delete(`${URL}removeUseCourseConnection`, {
                data: { FAUserID: facultyID, CourseID: courseID, User: user }
            });
            setSelectedConnection(null);
            alert("connection removed");
            fetchData();
        } catch (error) {
            console.error("Error removing connection:", error);
            setError("Failed to remove connection");
        } finally {
            setLoading(false);
        }
    };

    // these two filter so that when you choose a faculty member or Course, it will only let you chose the other that doesn't already have a connection with it
    const filteredFaculty = selectedCourse
        ? faculty.filter(fac => !connections.some(conn => conn.CourseID === parseInt(selectedCourse.value) && conn.UserID === fac.UserID))
        : faculty;

    const filteredCourses = selectedFaculty
        ? courses.filter(course => !connections.some(conn => conn.UserID === parseInt(selectedFaculty.value) && conn.CourseID === course.CourseID))
        : courses;

    return (
        <section id="use-CourseManager">
            <h1>Use-Course Connection Manager</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            <h2>Create Connection</h2>
            <form onSubmit={createConnection}>
                <Select
                    value={selectedFaculty}
                    onChange={setSelectedFaculty}
                    options={filteredFaculty.map(fac => ({ value: fac.UserID, label: fac.FullName }))}
                    placeholder="-- Select Faculty --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <Select
                    value={selectedCourse}
                    onChange={setSelectedCourse}
                    options={filteredCourses.map(course => ({ value: course.CourseID, label: `${course.CourseNum} - ${course.CourseName}` }))}
                    placeholder="-- Select Course --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Remove Connection</h2>
            <form onSubmit={removeConnection}>
                <Select
                    value={selectedConnection}
                    onChange={setSelectedConnection}
                    options={connections.map(conn => ({
                        value: `${conn.UserID}-${conn.CourseID}`,
                        label: `${conn.FullName} - ${conn.CourseName}`
                    }))}
                    placeholder="-- Select Connection --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
}

export default UseCourseConnectionManager;
