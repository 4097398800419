import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import { selectStyles } from '../../selectStyle';

const URL = window.env.REACT_APP_API_URL;

const CourseManagement = () => {
    const [courses, setCourses] = useState([]);
    const [newCourseNum, setNewCourseNum] = useState('');
    const [newCourseName, setNewCourseName] = useState('');
    const [newCourseDescription, setNewCourseDescription] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [editCourseNum, setEditCourseNum] = useState('');
    const [editCourseName, setEditCourseName] = useState('');
    const [editCourseDescription, setEditCourseDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        fetchCourses();
    }, []);

    useEffect(() => {
        if (selectedCourse) {
            const selectedCourseData = courses.find(course => course.CourseID == selectedCourse.value);
            if (selectedCourseData) {
                setEditCourseNum(selectedCourseData.CourseNum);
                setEditCourseName(selectedCourseData.CourseName);
                setEditCourseDescription(selectedCourseData.CourseDescription);
            }
        } else {
            setEditCourseNum('');
            setEditCourseName('');
            setEditCourseDescription('');
        }
    }, [selectedCourse, courses]);

    const fetchCourses = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${URL}courses/${user.UserID}`);
            setCourses(response.data);
        } catch (error) {
            alert("Error fetching courses:", error);
            setError('Failed to load courses');
        } finally {
            setLoading(false);
        }
    };

    const addCourse = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}addCourse`, {
                CourseNum: newCourseNum,
                CourseName: newCourseName,
                CourseDescription: newCourseDescription,
                User: user
            });
            setNewCourseNum('');
            setNewCourseName('');
            setNewCourseDescription('');
            alert(`${newCourseName} added!`);
            fetchCourses();
            window.dispatchEvent(new Event('courseListUpdated'));
        } catch (error) {
            console.error("Error adding course:", error);
            setError('Failed to add course');
        } finally {
            setLoading(false);
        }
    };

    const editCourse = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.put(`${URL}editCourse`, {
                CourseID: selectedCourse.value,
                CourseNum: editCourseNum,
                CourseName: editCourseName,
                CourseDescription: editCourseDescription,
                User: user
            });
            setSelectedCourse(null);
            setEditCourseNum('');
            setEditCourseName('');
            setEditCourseDescription('');
            alert(`${editCourseName} changed`);
            fetchCourses();
            window.dispatchEvent(new Event('courseListUpdated'));
        } catch (error) {
            console.error("Error editing course:", error);
            setError('Failed to edit course');
        } finally {
            setLoading(false);
        }
    };

    const removeCourse = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${URL}removeCourse`, {
                CourseID: selectedCourse.value,
                User: user
            });
            setSelectedCourse(null);
            alert(`Course removed`);
            fetchCourses();
            window.dispatchEvent(new Event('courseListUpdated'));
        } catch (error) {
            console.error("Error removing course:", error);
            setError('Failed to remove course');
        } finally {
            setLoading(false);
        }
    };

    const courseOptions = [
        { value: '', label: '-- Select Course --' },
        ...courses.map(course => ({
            value: course.CourseID,
            label: `${course.CourseNum} - ${course.CourseName}`
        }))
    ];

    if (loading) {
        return <p>Loading courses...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <section id="courseManager">
            <h1>Course Management</h1>
            <h2>Add Course</h2>
            <form onSubmit={addCourse}>
                <input
                    type="number"
                    value={newCourseNum}
                    onChange={(e) => setNewCourseNum(e.target.value)}
                    placeholder="Enter course number"
                    min="0"
                    required
                />
                <input
                    type="text"
                    value={newCourseName}
                    onChange={(e) => setNewCourseName(e.target.value)}
                    placeholder="Enter course name"
                    maxLength="100"
                    required
                />
                <textarea
                    value={newCourseDescription}
                    onChange={(e) => setNewCourseDescription(e.target.value)}
                    placeholder="Enter course description"
                    maxLength="1000"
                />
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Edit Course</h2>
            <form onSubmit={editCourse}>
            <Select
                    value={selectedCourse}
                    onChange={setSelectedCourse}
                    options={courseOptions}
                    placeholder="-- Select Course --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                {selectedCourse && (
                    <>
                        <input
                            type="number"
                            value={editCourseNum}
                            onChange={(e) => setEditCourseNum(e.target.value)}
                            placeholder="Enter new course number"
                            min="0"
                            required
                        />
                        <input
                            type="text"
                            value={editCourseName}
                            onChange={(e) => setEditCourseName(e.target.value)}
                            placeholder="Enter new course name"
                            maxLength="100"
                            required
                        />
                        <textarea
                            value={editCourseDescription}
                            onChange={(e) => setEditCourseDescription(e.target.value)}
                            placeholder="Enter new course description"
                            maxLength="1000"
                        />
                    </>
                )}
                <button type="submit" disabled={loading}>Submit</button>
            </form>
            <h2>Remove Course</h2>
            <form onSubmit={removeCourse}>
                <Select
                    value={selectedCourse}
                    onChange={setSelectedCourse}
                    options={courseOptions}
                    placeholder="-- Select Course --"
                    isSearchable
                    required
                    styles={selectStyles}
                />
                <button type="submit" disabled={loading}>Confirm</button>
            </form>
        </section>
    );
}

export default CourseManagement;
